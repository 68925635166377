<!-- eslint-disable max-len -->
<template>
    <div style="padding: 10px">
        <div style="background: #fff; border-radius: 8px; padding: 20px;">
            <div class="query-c">
                搜索：
                <Input search placeholder="请输入日志内容" style="width: auto" v-model="content"/>
                时间：
                <DatePicker @on-change="dateChange" type="datetimerange" placement="bottom-end" placeholder="Select date" style="width: 200px" />
                 用户名：
                <Input search placeholder="请输入用户名" style="width: auto" v-model="username"/>
                分类：
                   <Dropdown trigger="click" style="margin-left: 20px" @on-click="categoryClick">
                    <a href="javascript:void(0)">
                    {{category}}
                    <Icon type="ios-arrow-down"></Icon>
                    </a>
                    <template #list>
                    <DropdownMenu>
                        <DropdownItem v-for="item in categorys" :key="item.code" :name="item.code">{{ item.value }}</DropdownItem>
                    </DropdownMenu>
                    </template>
                    </Dropdown>

                <div style="display: flex; align-items: center">
                    <button @click="handleSearch" class="search-button">搜索</button>
                </div>
            </div>
            <br>
            <Table max-height="440"  border highlight-row  stripe :columns="columns1" :data="data1"></Table>
            <br>
        <Page :total="total" show-sizer  prev-text="上一页" @on-change="change" @on-page-size-change="pageSizeCall" next-text="下一页" />
        </div>
    </div>
</template>

<script>
import { logPage, categoryList } from '@/api/log.js'

export default {
    name: 'logRecord',
    data() {
        return {
            categorys: [],
            category: '分类选择',
            categoryCode: '',
            username: '',
            startTime: '',
            endTime: '',
            pageSize: 10,
            firstResult: 0,
            content: '',
            total: 0,
            columns1: [
                {
                    title: 'LogId',
                    key: 'logId',
                    render: (h, params) => h('div', [
                        // 显示的位置上方
                        h('Tooltip', {
                            props: { placement: 'top', transfer: true },
                        }, [
                            h('span', {
                                style: {
                                    display: 'inline-block',
                                    width: '100px',
                                    overflow: 'hidden',
                                    // 同样在这里隐藏溢出内容
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }, params.row.logId),
                            // 显示的内容
                            h('span', {
                                slot: 'content',
                                style: { whiteSpace: 'normal', wordBreak: 'break-all' },
                            }, params.row.logId),
                        ]),
                    ]),
                },
                {
                    title: '操作人',
                    key: 'operator',
                },
                {
                    title: '业务id',
                    key: 'bizNo',
                    render: (h, params) => h('div', [
                        // 显示的位置上方
                        h('Tooltip', {
                            props: { placement: 'top', transfer: true },
                        }, [
                            h('span', {
                                style: {
                                    display: 'inline-block',
                                    width: '100px',
                                    overflow: 'hidden',
                                    // 同样在这里隐藏溢出内容
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }, params.row.bizNo),
                            // 显示的内容
                            h('span', {
                                slot: 'content',
                                style: { whiteSpace: 'normal', wordBreak: 'break-all' },
                            }, params.row.bizNo),
                        ]),
                    ]),
                },
                {
                    title: '内容',
                    key: 'extra',
                    render: (h, params) => h('div', [
                        // 显示的位置上方
                        h('Tooltip', {
                            props: { placement: 'top', transfer: true },
                        }, [
                            h('span', {
                                style: {
                                    display: 'inline-block',
                                    width: '100px',
                                    overflow: 'hidden',
                                    // 同样在这里隐藏溢出内容
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }, params.row.extra),
                            // 显示的内容
                            h('span', {
                                slot: 'content',
                                style: { whiteSpace: 'normal', wordBreak: 'break-all' },
                            }, params.row.extra),
                        ]),
                    ]),
                },
                {
                    title: '日志分类',
                    key: 'category',
                },
                {
                    title: '分类明细',
                    key: 'detail',
                },
                {
                    title: '发生时间',
                    key: 'createTime',
                },
                {
                    title: '用户名',
                    key: 'username',
                },
                {
                    title: 'IP',
                    key: 'ip',
                },
            ],
            data1: [],
        }
    },
    methods: {
        getCategoryList() {
            categoryList({
            }).then(res => {
                this.categorys = res.retVal
            })
        },
        categoryClick(val) {
            // 使用 Array.prototype.find 方法查找对象
            const targetObject = this.categorys.find(obj => obj.code === val)
            this.category = targetObject.value
            this.categoryCode = targetObject.code
        },
        handleSearch() {
            this.getPage()
        },
        dateChange(val1) {
            this.startTime = val1[0]
            this.endTime = val1[1]
        },
        calculateStartData(currentPage, pageSize) {
            let startData = (currentPage - 1) * pageSize
            return startData
        },
        pageSizeCall(val) {
            this.pageSize = val
        },
        change(val) {
            this.firstResult = this.calculateStartData(val, this.pageSize)
            this.getPage()
        },
        getPage() {
            logPage({
                username: this.username,
                detail: '',
                category: this.categoryCode,
                startTime: this.startTime,
                endTime: this.endTime,
                pageSize: this.pageSize,
                firstResult: this.firstResult,
                search: this.content,
            }).then(res => {
                this.data1 = res.retVal.datas
                this.total = res.retVal.totalRows
            })
        },
    },
    mounted() {
        this.getCategoryList()
        this.getPage()
    },
}
</script>

<style scoped>
.search-button {
    padding: 8px 16px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
}
</style>